import React, { useEffect, useState } from 'react'
import './RowScale.css'
import Slider from '@mui/material/Slider';
import { convertLength } from '@mui/material/styles/cssUtils';


function RowScale(props) {

  const [Marks, setMarks] = useState([
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },

  ]);

  const [sliderValue, setsliderValue] = useState([])
  // function handleLeftClick(scale) {
  //   if (scale == 4) {
  //     setValue_1(Value_1 - 1 < 0 ? 0 : Value_1 - 1);
  //   } else if (scale == 5) {
  //     setValue_2(Value_2 - 1 < 0 ? 0 : Value_2 - 1)
  //   } else if (scale == 6) {
  //     setValue_3(Value_3 - 1 < 0 ? 0 : Value_3 - 1)
  //   } else if (scale == 7) {
  //     setValue_4(Value_4 - 1 < 0 ? 0 : Value_4 - 1)
  //   }
  // }

  // function handleRightClick(scale) {
  //   if (scale == 4) {
  //     setValue_1(Value_1 + 1 > 10 ? 10 : Value_1 + 1);
  //   } else if (scale == 5) {
  //     setValue_2(Value_2 + 1 > 10 ? 10 : Value_2 + 1)
  //   } else if (scale == 6) {
  //     setValue_3(Value_3 + 1 > 10 ? 10 : Value_3 + 1)
  //   } else if (scale == 7) {
  //     setValue_4(Value_4 + 1 > 10 ? 10 : Value_4 + 1)
  //   }
  // }

  function handleLeftClick(scale) {
    // console.log("1- scale",scale);
    const value = sliderValue.slice();
    if (value[scale] == undefined) {

      value[scale] = 0
      setsliderValue(value);
    }

    value[scale] -= 1;
    // console.log("2- value",value);
    setsliderValue(value);
    // console.log("3- slider value after setFunc",sliderValue[scale]);
    // console.log(sliderValue);
  }




  function handleRightClick(scale) {

    // console.log("1- scale",scale);
    const value = sliderValue.slice();
    if (value[scale] == undefined) {

      value[scale] = 0
      setsliderValue(value);
    }

    value[scale] += 1;
    // console.log("2- value",value);
    setsliderValue(value);
    // console.log("3- slider value after setFunc",sliderValue[scale]);
    // console.log(sliderValue);

  }




  useEffect(() => {
    // console.log("chala bhai chala");
  }, [sliderValue])

  const fetchedValues = [sliderValue]
  props.getFetchedScales(fetchedValues);


  return (
    <>

      {props.surveyForm.map((survey, index) =>

        (survey.type == 'scale') ?

          <table className="table-scale table table-borderless">
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "left" }}>{index + 1}) {survey.question.replace('%companyName%', props.companyName)}</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td className='wholeScale'>

                  <div className="row">
                    <div className="col-sm-2 leftBtn">
                      <img onClick={(e) => { handleLeftClick(index + 1) }} className='resp-navScale' style={{ height: "15px" }} src="leftBtn.png" alt="hey" />
                      <span className='degEx' style={{ display: "block", marginTop: "10px", cursor: "pointer" }}>Very Unlikely</span>
                    </div>
                    <div className="col-sm-8" style={{ marginTop: "1rem" }}>
                      <Slider
                        value={sliderValue[index + 1] ?? ""}
                        defaultValue={1}
                        id={`slider-${index + 1}`}
                        max={10}
                        marks={Marks}
                        valueLabelDisplay="on"

                        name={`scale-${index + 1}`}
                        sx={{
                          '.MuiSlider-valueLabel': {
                            background: "#F26D2B",
                            width: "1rem",
                            height: "1rem",
                            padding: "10px",
                            marginTop: "1px"
                          },
                          ".MuiSlider-valueLabelLabel": {
                            color: "white",
                            fontSize: "10px",

                          },

                        }}
                      />
                    </div>
                    <div className="col-sm-2 rightBtn">
                      <img className=" resp-R" onClick={() => { handleRightClick(index + 1) }} value={index + 1} style={{ height: "15px" }} src="rightBtn.png" alt="hey" />
                      <span className='degEx resp-navScale' style={{ display: "block", marginTop: "10px", cursor: "pointer" }}>Very likely</span>
                    </div>
                  </div>



                </td>

              </tr>

              {/* </div> */}

            </tbody>
          </table>
          : <></>
      )
      }


    </>

  );
}


export default RowScale