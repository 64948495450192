import Firebase from "firebase";

 // Your web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyA7foe2SFmdla6LSOapzOD20iALprNk1cg",
    authDomain: "survey-ttg.firebaseapp.com",
    databaseURL: "https://survey-ttg.firebaseio.com",
    projectId: "survey-ttg",
    storageBucket: "survey-ttg.appspot.com",
    messagingSenderId: "372228062545",
    appId: "1:372228062545:web:61e0f01e3949b232136770",
    measurementId: "G-CKLRSJT850"
  };

const app = Firebase.initializeApp(firebaseConfig);
export const db = app.database();