import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';


import './RowRating.css'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function RowRating(props) {
  const [likertValues, setlikertValues] = useState([]);

  const values = [...likertValues,[]]
  
  function getRating(e) {
    const { name, value } = e.target;
    // const likert = likert;
    const key = name.slice(0,1)
    // console.log("key type", typeof(key));
    
    values[key]=[...values[key],value]

const filteredValues=values.filter(fv=>{
  return fv.length>0
})

    // console.log(filteredValues)

    

    // console.log(values);
    setlikertValues(filteredValues);

    // console.log(likertValues);

  }

  // /* Rating */
  // const [Rating_a, setRating_a] = useState([]);
  // const [Rating_b, setRating_b] = useState([]);
  // const [Rating_c, setRating_c] = useState([]);
  // const [Rating_d, setRating_d] = useState([]);
  // const [Rating_e, setRating_e] = useState([]);


  // function getRating(e) {
  //   const { name, value } = e.target;

  //   name == '0k' && setRating_a(value) ||
  //     name == '1k' && setRating_b(value) ||
  //     name == '2k' && setRating_c(value) ||
  //     name == '3k' && setRating_d(value) ||
  //     name == '4k' && setRating_e(value)

  // }



  const fetchedRating = [likertValues];


  props.getFetchedRatings(fetchedRating);

  //  console.log(fetchedRating);
  return (
    <>
      {props.surveyForm.map((survey, index) =>
        (survey.type == 'rating') ?

          <table className="table table-borderless">
            <thead>
              <tr>
                <th className=".ques" scope="" style={{ textAlign: "left" }}>{index + 1}) {survey.question.replace('%companyName%', props.companyName)}</th>
                {survey.options.map(option =>
                  <td className='deg' scope="col">{option}</td>
                )}
                {/* <td className='deg' scope="col">Disagree</td>
                <td className='deg' scope="col">Neutral</td>
                <td className='deg' scope="col">Agree</td>
                <td className='deg' scope="col">Strongly Agree</td> */}
              </tr>
            </thead>
            <tbody>
              {
                survey.subQuestions.map((sq, i) => (

                  <tr>
                    <td style={{ textAlign: "left", color: !document.getElementsByClassName('form-check-input customInput').checked ? "red" : "black" }}>* {sq}</td>
                    {

                      survey.options.map((h, x) => (

                        <td key={`td=${index}-${i}-${x}`} className='text-center'>
                          <div key={`div=${index}-${i}-${x}`} className="form-check form-check-inline mq">
                            {/* <input style={{ marginLeft: 0 }} key={`rating-question${index}-c${i}-o${x}`} onChange={getRating} className="form-check-input customInput" type="radio" name={index + i + 'k'} id="inlineRadio1" value={`rating-question${index}-c${i}-o${x}`} /> */}
                            <input required style={{ marginLeft: 0, }} key={`rating-question${index}-c${i}-o${x}`} onChange={getRating} className="form-check-input customInput" type="radio" name={`${index}-${i}`} id="inlineRadio1" value={x} />
                          </div>
                        </td>
                      ))
                    }

                  </tr>
                )
                )
              }

            </tbody>
          </table>
          : <></>
      )
      }
    </>

  );
}

export default RowRating;
