import React, { useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import { db } from './FirebaseDbConfig.js';
import surveyForm from './data/surveyForm.json';

import RowRating from './components/RowRating';
import RowRadio from './components/RowRadio';
import RowScale from './components/RowScale';
import RowTextBox from './components/RowTextBox';
import { useEffect } from 'react';
import axios from 'axios'
import { formControlUnstyledClasses } from '@mui/base';

// import Rodal from 'rodal';
// // include styles
// import 'rodal/lib/rodal.css';




function App() {

  const [surveyFormAxios, setsurveyFormAxios] = useState([]);


  const getMyJsonData = async () => {
    await axios.get(`./surveyForm.json`)
      .then(res => {
        const response = res.data;
        setsurveyFormAxios(response);
      }).catch(er => {
        console.log(er)
      })


  }


  useEffect(() => {
    getMyJsonData()
  }, []);


  /* for ratings */
  let Ratings;

  const getFetchedRatings = fetchedRatings => {
    // setRatings(fetchedRatings);
    Ratings = fetchedRatings;
    // filtered = chunkArr(Ratings[],5);

    const filtered = [];
    for (let id in Ratings[0]) {
      filtered.push(fetchedRatings[0][id])
    }

    function chunkArr(arr, limit) {
      const finalArr = [];

      return finalArr;
    }



  }
  /* for radios */
  let Radios;
  let filteredRadios;
  const getFetchedRadios = fetchedRadios => {
    Radios = fetchedRadios;
    filteredRadios = Radios.filter(d => {
      return d !== undefined
    })



    // console.log("mainR",Radios)


  }

  /* for scales */
  let Scales;
  let filteredScales;
  const getFetchedScales = fetchedScales => {
    Scales = fetchedScales;

    filteredScales = Scales[0].filter(fs => {
      return fs !== undefined;
    })
    // console.log("mainScales",Scales)


  }
  /* for textboxes */
  let TextBoxes;
  let filteredTextBoxes;
  const getFetchedTextBoxes = fetchedTextBoxes => {
    TextBoxes = fetchedTextBoxes;
    filteredTextBoxes = TextBoxes[0].filter(d => {
      return d !== undefined
    })



  }

  /* send signals to components when click event occur */
  const [submit, setsubmit] = useState(false)
  const handleSubmit = () => {
    setsubmit(true);
    const SubmittedData = [...Ratings, ...filteredRadios, ...filteredScales, ...filteredTextBoxes];
    console.log(SubmittedData);

  }


  const queryString = window.location.search;
  // console.log(queryString);
  const urlParams = new URLSearchParams(queryString);
  const companyName = urlParams.get('companyName')



  const [survery, setSuvery] = useState([]);

  // const openClose = () => {
  //   document.getElementsByTagName("Rodal").visible = false;
  // }

  const onChange = (e) => {
    // document.getElementsByTagName("Rodal").visible = true;  
    document.getElementsByClassName("input-submit").disabled = true;
    surveyForm[e.target.name]["submited"] = surveyForm[e.target.name]["options"][e.target.value];
    document.getElementById("option-" + e.target.name).style.borderLeft = null;
  }


  const onClick = () => {

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let userId = urlParams.get('userId');
    let emailAddress = urlParams.get('email');
    let surveySubmission = surveyForm;
    // let emailAddress = document.getElementById('email-id').value;
    let isVerified = true;
    surveySubmission.forEach(function (survey, index) {
      if ("submited" in survey) {
        // console.log("exists")
        delete survey["options"];
        delete survey["type"];
      }
      else {
        document.getElementById("option-" + index).style.borderLeft = "solid #ff000057"; isVerified = false;
      }
    });

    // console.log("isVerified : ",isVerified);

    if (isVerified) {
      // console.log(surveyForm);            
      db.ref().child("SubmitedForms").push(
        {
          "userId": userId,
          "emailAddress": emailAddress,
          "survey": surveySubmission
        }, function (error) {
          //console.log("fun"); 
          if (error) {
            alert("Error: There is some issue while saving data ");
            // console.log("Error: There is some issue while saving data ");    
          }
          else {
            document.getElementsByClassName("input-submit").disabled = true;
            //alert(document.getElementById("sucesspop").innerHTML);
            alert("Survey Submitted Successfully");
            document.getElementById("background-layer").style.display = "block"
          }
        }
      )
    }
    else {
      alert("Please fill all the survey questions");
    }


  };

  const containerStyle = {
    marginTop: "65px"
  }

  return (
    // <div id="root container">
    <div className="App">

      <form onSubmit={(e) => { e.preventDefault() }}>
        {/* <form action='http://www.google.com'> */}
        <div className="container wrapApp">
          <div className="col-sm-9 container">

            <header className="App-header"><div className="App-logo"></div></header>
          </div>
          <div id="main" style={containerStyle} className="container rounded shadow p-4">
            <div className="row mainRow">

              {/* <Rodal true onClose={openClose}>
           <div>Content</div>
         </Rodal> */}

              <div id="background-layer">
                <div id="sucesspop">
                  <div className="success-inner"><span className="imesg">Thank you for playing the demo, please check your inbox to see your assessment scores</span><span className="closediv" id="okb"><span>OK</span></span></div>
                </div>
              </div>
              <div className="htmlForm_div">
                <h1>THE TALENT GAMES</h1>
                <h1 style={{ color: "#F26D2B" }}>CANDIDATE SURVEY</h1>
                <p className="heading">Answer these questions below to get your assesment scorecard</p>
              </div>
              <div className="col-sm-9 container my-1">
                <RowRating isSubmit={submit} getFetchedRatings={getFetchedRatings} companyName={companyName} surveyForm={surveyFormAxios} />
              </div>

              <div className="col-sm-9 container">
                <RowRadio getFetchedRadios={getFetchedRadios} companyName={companyName} surveyForm={surveyFormAxios} />
              </div>
              <div className="col-sm-9 container">
                <RowScale getFetchedScales={getFetchedScales} companyName={companyName} surveyForm={surveyFormAxios} />
              </div>
              <div className="col-sm-9 container">
                <RowTextBox getFetchedTextBoxes={getFetchedTextBoxes} companyName={companyName} surveyForm={surveyFormAxios} />
              </div>

              <div className="col-sm-12 container">
                <button style={{ background: "#F36E2C", border: "1px solid #F36E2C" }} onClick={handleSubmit} className='btn btn-success submit' type='submit'>Assesment Score</button>
              </div>

            </div>

          </div>

        </div>
      </form>
    </div>
    // </div>
  );
}

export default App;