import React from 'react'
import { useState } from 'react';
import './RowRadio.css'

function RowRadio(props) {

    const [radios, setradios] = useState([]);
    function getRadio(e) {
        const { name, value } = e.target;
        const radio = radios.slice();
        radio[name] = value;
        setradios(radio);
        // console.log(radios)

    }

    const fetchedRadios = radios;
    props.getFetchedRadios(fetchedRadios);
    // console.log(props.surveyForm, "data is ")


    return (
        <>
            {props.surveyForm.map((survey, index) =>
                (survey.type == 'radio') ?
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" style={{ textAlign: "left" }}>{index + 1}) {survey.question.replace('%companyName%', props.companyName)}</th>
                                {/* <td scope="col">Strongly Disgree</td>
                                <td scope="col">Disagree</td>
                                <td scope="col">Neither Agree<br /> nor Disagree</td>
                                <td scope="col">Agree</td>
                                <td scope="col">Strongly Agree</td> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                survey.options.map((op, i) => (

                                    <tr>
                                        <td style={{ textAlign: "left" }}>
                                            {/* <input onChange={getRadio} className="form-check-input customInput" key={index + '' + i} id={'radio-' + index + '' + i} name={index} type="radio" value={`radio-question${index + 1}-o${i}`}>
                                            </input> */}
                                            <input required onChange={getRadio} className="form-check-input customInput" key={index + '' + i} id={'radio-' + index + '' + i} name={index} type="radio" value={i}>
                                            </input>
                                            <span>  </span>
                                            <label htmlFor={'radio-' + index + '' + i} className="radio-label">{op}</label>
                                        </td>
                                        {/* {
                                            survey.subQuestions.map((h, x) => (
                                                <td className='text-center'>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input customInput" type="radio" name={index + i} id="inlineRadio1" value="option1" />
                                                    </div>
                                                </td>
                                            ))
                                        } */}

                                    </tr>
                                )
                                )
                            }

                        </tbody>
                    </table>
                    : <></>
            )
            }
        </>

    );
}

export default RowRadio