import React, { useState } from 'react'
function RowTextBox(props) {

    /* Radio */
    const [TextBox, setTextBox] = useState([]);



    function getTextBox(e, index) {
        const { name, value } = e.target


        let newData = [...TextBox]
        newData[index] = value;
        // // newData.splice(index);
        setTextBox(newData);
        // console.log(newData)


    }

    const fetchedTextBoxes = [TextBox];
    props.getFetchedTextBoxes(fetchedTextBoxes);

    return (
        <>
            {props.surveyForm.map((survey, index) =>
                (survey.type == 'textBox') ?
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" style={{ textAlign: "left" }}>{index + 1}) {survey.question.replace('%companyName%', props.companyName)}</th>
                                {/* <td scope="col">Strongly Disgree</td>
                            <td scope="col">Disagree</td>
                            <td scope="col">Neither Agree<br /> nor Disagree</td>
                            <td scope="col">Agree</td>
                            <td scope="col">Strongly Agree</td> */}
                            </tr>
                        </thead>
                        <tbody>
                        <tr>

                            <td>
                                <div className="row">

                                    <div className="col-sm-12" >

                                        <textarea
                
                                            name={index}
                                            onChange={(e) => getTextBox(e, index)} style={{ border: "2px solid #F36E2C" }} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                </div>
                            </td>
                            </tr>

                        </tbody>
                    </table>
                    : <></>
            )
            }
        </>
    )
}

export default RowTextBox